import { useState } from "react";
import { ArrowDownwardOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import CryptoJS from "crypto-js";

const CoAppKeyGenerator = () => {
	const [masterKey, setMasterKey] = useState("");
	const [coAppKey, setCoAppKey] = useState("");
	const [encryptedKey, setEncryptedKey] = useState("");

	const handleEncrypt = () => {
		const encrypted = CryptoJS.AES.encrypt(coAppKey, masterKey).toString();
		setEncryptedKey(encrypted);
	};

	return (
		<div>
			<h2>CoApp Key Generator</h2>
			<div>
				<label>
					Master Key:
					<input
						type="password"
						value={masterKey}
						onChange={(e) => setMasterKey(e.target.value)}
					/>
				</label>
			</div>
			<div>
				<label>
					CoApp Key:
					<input
						type="text"
						value={coAppKey}
						onChange={(e) => setCoAppKey(e.target.value)}
					/>
				</label>
			</div>
			<button onClick={handleEncrypt}>Encrypt</button>
			{encryptedKey && (
				<div>
					<h3>Encrypted Key:</h3>
					<textarea readOnly value={encryptedKey} />
				</div>
			)}
		

			<Accordion>
				<AccordionSummary 
					expandIcon={<ArrowDownwardOutlined />}
					aria-controls="panel1-content"
					id="panel1-header">
					<Typography>Source Code:</Typography>
				</AccordionSummary>
				<AccordionDetails
				>
					<pre>
						<code>
							{`

		const CoAppKeyGenerator = () => {
			const [masterKey, setMasterKey] = useState("");
			const [coAppKey, setCoAppKey] = useState("");
			const [encryptedKey, setEncryptedKey] = useState("");

			const handleEncrypt = () => {
				const encrypted = CryptoJS.AES.encrypt(coAppKey, masterKey).toString();
				setEncryptedKey(encrypted);
			};

			return (
				<div>
					<h2>CoApp Key Generator</h2>
					<div>
						<label>
							Master Key:
							<input
								type="password"
								value={masterKey}
								onChange={(e) => setMasterKey(e.target.value)}
							/>
						</label>
					</div>
					<div>
						<label>
							CoApp Key:
							<input
								type="text"
								value={coAppKey}
								onChange={(e) => setCoAppKey(e.target.value)}
							/>
						</label>
					</div>
					<button onClick={handleEncrypt}>Encrypt</button>
					{encryptedKey && (
						<div>
							<h3>Encrypted Key:</h3>
							<textarea readOnly value={encryptedKey} />
						</div>
					)}
				</div>
			);
		};

		export default CoAppKeyGenerator;
					`}
						</code>
					</pre>
				</AccordionDetails>
			</Accordion>
	
		</div>
	);
};

export default CoAppKeyGenerator;